@import '~@/assets/scss/global.scss';

.cursor-pointer {
  cursor: pointer;
}
.comment-content p,
.inside-timer p {
  font-size: 14px;
  margin-bottom: 0;
}
.comment-content p:first-letter,
.inside-timer p::first-letter {
  text-transform: capitalize;
}
.comment-single-task .attach-outline {
  border: 1px dotted;
}

.comment-single-task .delete-preview-button {
  right: 5px;
  top: 5px;
}

.comment-content {
  display: block;
  width: 100%;
}

.attachment-filename {
  position: absolute;
  bottom: -1px;
  background-color: #f7f7f7;
  width: 100%;
  border-radius: 0 0 6px 6px;
  color: #000;
}

blockquote {
  border-left: 3px solid rgba(#0d0d0d, 0.1);
  padding-left: 1rem;
}

.attachments::v-deep,
#comments::v-deep {
  .delete-preview {
    right: -10px;
    top: -10px;
  }
  .d-flex.justify-center.align-center.v-card.v-sheet.theme--light {
    height: 127px;
  }
}
.launch-icon-image {
  top: -6em;
  left: 4em;
}
.theme--dark .v-time-picker-clock__item--active > span {
  color: #000;
}

@import './styleguide/colors.scss';

.auth-left-column {
	background: $blue-gradient;

	img {
		padding-bottom: 20px;
	}

	.taglines {
		.heading {
			display: inline-block;
		}

		hr {
			max-width: 30%;
			margin: 0 auto;
			border: 0;
			border-bottom: 5px solid #fff;
		}
	}
}

